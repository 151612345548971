import { createBrowserHistory } from "history";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ComponentType } from "react";
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
// *** Add the Click Analytics plug-in. ***
const clickPluginInstance = new ClickAnalyticsPlugin();
const clickPluginConfig = {
	autoCapture: true
};
const appInsights = new ApplicationInsights({
	config: {
		connectionString: 'InstrumentationKey=54aacf57-1797-4e9a-b28e-8cdbf0706b94;' +
			'IngestionEndpoint=https://eastus2-3.in.applicationinsights.azure.com/;' +
			'LiveEndpoint=https://eastus2.livediagnostics.monitor.azure.com/;' +
			'ApplicationId=8c2084ee-f4f3-468e-8b31-998592f4b16a',
		// *** If you're adding the Click Analytics plug-in, delete the next line. ***
		extensions: [reactPlugin, clickPluginInstance],
		// *** Add the Click Analytics plug-in. ***
		// extensions: [reactPlugin, clickPluginInstance],
		extensionConfig: {
			[reactPlugin.identifier]: { history: browserHistory },
			// *** Add the Click Analytics plug-in. ***
			[clickPluginInstance.identifier]: clickPluginConfig,
		}
	}
});
appInsights.loadAppInsights();

function withAppInsights<P>(Component: ComponentType<P>) {
	return withAITracking(reactPlugin, Component)
}

export { reactPlugin, withAppInsights, appInsights };
