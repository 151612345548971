import React from 'react';
import './index.css';

import { AppRouter } from 'app/providers/router';
import { ThemeProvider } from "@mui/material";
import { theme } from 'shared/config/theme/theme';
import ErrorBoundary from 'app/providers/errorBoundary';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppInsightsContextProvider } from './app/providers/AppInsights';

function App() {
	return (

		<ThemeProvider theme={theme}>
			<AppInsightsContextProvider>
				<ErrorBoundary>
					<ToastContainer/>
					<AppRouter/>
				</ErrorBoundary>
			</AppInsightsContextProvider>
		</ThemeProvider>
	);
}

export default App;
