import { Parameter } from 'shared/api/assurant/quotePayloadTypes';
import { JourneyResult } from '../config/types/customerResponse';

const floodIncludedStates = ['CT', 'NJ', 'NY', 'TX']

export const paramsAdapt = (customerData: JourneyResult) => {
	let params = customerData.coverage_info.parameters as Parameter[]
	if(customerData.property_address.state === 'NC') {
		params = [...params, { name: 'hasWaterDamageLiability', value: 'Y' } as Parameter]
	}
	if(floodIncludedStates.includes(customerData.property_address.state)) {
		params = [...params, { name: 'floodIncluded', value: 'Y' } as Parameter]
	}
	return params
}
