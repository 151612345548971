import React from 'react'
import {Radio} from "@mui/material";
import {ControlLabel, LabelWrapper, Popular, RadioWrapper, StyledModeEditIcon} from './styles';
import {ReactComponent as Checked} from "assets/images/select.svg";
import {ReactComponent as UnChecked} from "assets/images/ellipse.svg";
import { IconButton } from "@mui/material";

interface Props {
	checked: boolean,
	value: string,
	label: string,
	label2?: string,
	popular?: boolean,
	handleEdit?: () => void,
	showEdit?: boolean,
}

const RadioButton = ({checked, value, label, label2, popular, handleEdit, showEdit}: Props) => {
	const Label = (
		<LabelWrapper>
			<div>
				{label}
				<br />
				{label2}
			</div>
			{popular && <Popular>Popular</Popular>}
		</LabelWrapper>
	)
	return (
		<RadioWrapper>
			<ControlLabel checked={checked}
				control={<Radio value={value} disableRipple icon={<UnChecked/>} checkedIcon={<Checked/>}/>} label={Label}/>
			{showEdit && (
				<IconButton onClick={handleEdit} sx={{ padding: '0', backgroundColor: 'transparent' }}>
					<StyledModeEditIcon />
				</IconButton>
			)}
		</RadioWrapper>
	)
}

export default RadioButton
