import { styled } from '@mui/material';
import { theme } from 'shared/config/theme/theme';

export const InfoBlock = styled('div')(({theme}) => ({
	display: 'flex',
	alignItems: 'center',
	backgroundColor:theme.palette.darkBlue.secondary,
	padding: theme.spacing(2),
	margin: `${theme.spacing(2)} 0`,
}))
export const ImgBlock = styled('div')(() => ({
	width: '34px',
	height: '34px',
	marginRight: theme.spacing(2),
}))

