/* eslint-disable */
import { Question } from "shared/api/Questions";
import { JourneyResult } from "shared/config/types/customerResponse";

export const getQuestionAnswer = (question: Question, journeyResultData: JourneyResult) => {
	if(!journeyResultData) return undefined;
	const schemaData = journeyResultData.underwriting.questions.reduce((accum: { [x: string]: any; }, item: { questionName: string | number; answer: any; }) => {
		accum[item.questionName] = item.answer
		return accum
	}, {})
	let questionOptionId;
	let value: string | undefined;

	switch (question.questionName) {
		case 'AnyPersonalLiabilityLossesInLast3Years-MI':
			value = schemaData.HaveYouHadAnyPersonalLiabilityLossesInThePast3Years;
			break;
		case 'DoYouOwnAnyAnimals':
			value = schemaData.DoYouOwnAnyAnimals;
			break;
		case 'Is300kRequiredByPmcOrForUmbrella-MI':
			value = schemaData.ThreeKLliabilityRequiredByLandlordManagementCompanyOrForAnUmbrellaPolicy;
			break;
		case 'IsLiabilityRequiredByLandLordOrPMC':
			value = schemaData.IsLiabilityRequiredByLandLordOrPMC;
			break;
		case 'DoYouOwnOrCareForAnyAnimals':
			value = schemaData.DoYouOwnOrCareForAnyAnimals;
			break;
		case 'AreDogsExcludedBreeds':
			value = schemaData.AreAnyOfTheDogsTheFollowingBreeds;
			break;
		case 'AreAnyDogsServiceAnimals':
			value = schemaData.AreAnyOfTheseDogsAServiceAnimal;
			break;
		case 'AnimalsCausedBodlyInjuredPastThreeYears':
			value = schemaData.OwnAnimalInThePropertyHarmedSomeone;
			break;
		case 'PropertyHavePool':
			value = schemaData.DoesThePropertyHaveAPool;
			break;
		case 'PoolScreenedFencedSecured':
			value = schemaData.IsThePoolScreenedFencedInAtLeast4FeetTallOrSecuredAgainstIntruders
			break;
		case 'LiabilityLosesInPastThreeYears':
			value = undefined;
			break;
		default:
			value = undefined;
			break;
	}
	question.options.map((option: any) => {
		if (option.optionValue === value) {
			questionOptionId = option.questionOptionId;
		}
	});
	return questionOptionId;
};
