/* eslint-disable */
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { UwQuestionsContext } from '../lib/UwQuestionsContext';
import { Question, QuestionsType, ResolutionResponse, UwQuestionResponse, UwQuestionsApi } from 'shared/api/Questions';
import UwQuestions from 'shared/api/assurant/uwquestions';
import { getQuestionAnswer } from 'shared/utils/getQuestionAnswers';
import { AnswerPayloadData } from 'shared/api/Questions/types';
import { BusinessRule, JourneyResult, QuoteResult } from 'shared/config/types/customerResponse';
import { useQueryClient } from '@tanstack/react-query';

interface CustomerStateProps {
	children: ReactNode
	data: QuoteResult
	customerData: JourneyResult
}

export const UwQuestionsProvider = ({ children, data, customerData }: CustomerStateProps) => {
	const [questionsCollection, setQuestionsCollection] = useState<Record<string, string>>({})
	const [outcomeUrl, setOutcomeUrl] = useState<string>('');
	const [dataQuestions, setDataQuestions] = useState<UwQuestionResponse[] | null>(null);
	const [dataAnswers, setDataAnswers] = useState<ResolutionResponse | null>(null);
	const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);
	const initialRuleRef = useRef<string | null>(null);
	const queryClient = useQueryClient()


	const setQuestionsData = useCallback((data: Record<string, string>) => {
		setQuestionsCollection((prev) => ({ ...prev, ...data }))
	}, []);

	const getQuestionsDataAsync = useCallback(async () => {
		setIsLoadingQuestions(true);
		try {
			if (data?.coveragesCue.businessRules) {
				const businessRule: BusinessRule | undefined = data?.coveragesCue.businessRules.find((item) => item.rule300KLiability);
				if (businessRule?.rule300KLiability.initialUrl && initialRuleRef.current !== businessRule?.rule300KLiability.initialUrl) {
					const initialUrl = businessRule.rule300KLiability.initialUrl;
					initialRuleRef.current = initialUrl
					setOutcomeUrl(businessRule.rule300KLiability.outcomeUrl)
					const data2 = await UwQuestions.getQuestions(initialUrl)
					if (data2.data) {
						setDataQuestions(data2.data)
					}
				}
			}
		} catch (e) {
			// window.location.href = 'https://renterscoverage.rein.ai/';
		} finally {
			setIsLoadingQuestions(false);
		}
	}, [data?.coveragesCue.businessRules]);

	const setQuestionsDataAsync = useCallback(async () => {
		try {
			if (outcomeUrl && dataQuestions) {
				const answers: QuestionsType[] = [];
				dataQuestions[0].questions.map((item: Question) => {
					if (getQuestionAnswer(item, customerData)) {
						answers.push({
							QuestionId: item.questionId,
							QuestionOptionId: getQuestionAnswer(item, customerData),
						});
					}
				})
				const data: AnswerPayloadData = {
					QuestionnaireId: dataQuestions[0].questionnaireId,
					Answers: answers,
				};
				const answersData = await UwQuestions.sendAnswers({ data, outcomeUrl });
				if (answersData.data) {
					setDataAnswers(answersData.data)
					queryClient.setQueryData(['resolution'], answersData.data)
				}
			}
		} catch (e) {
			console.log('e: ', e);
		}
	}, [outcomeUrl, dataQuestions, customerData]);

	useEffect(() => {
		setQuestionsDataAsync().then()
	}, [setQuestionsDataAsync]);

	const value = useMemo(() => {
		return {
			dataQuestions,
			dataAnswers,
			questionsCollection,
			setQuestionsData,
			getQuestionsDataAsync,
			isLoadingQuestions,
			setQuestionsCollection
		}
	}, [dataQuestions, dataAnswers, questionsCollection, setQuestionsData, getQuestionsDataAsync, isLoadingQuestions]);

	return (
		<UwQuestionsContext.Provider value={value}>
			{children}
		</UwQuestionsContext.Provider>
	)
}
