import { styled } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const Label = styled('label')(({theme}) => ({
	display: 'block',
	marginBottom: '5px',
	marginTop: '5px',
	fontFamily: theme.typography.fontFamily,
	fontStyle: 'normal',
	fontWeight: 400,
	fontSize: theme.typography.fontSize,
	lineHeight: '24px',
	color: theme.palette.text.primary,
}))

export const Group = styled('div')({
	margin: '15px 0',
	flexDirection: 'column',
	display: 'flex',
})

export const SelectFieldWrapper = styled('div')(({theme}) => ({
	flexDirection: 'column',
	display: 'flex',
	width: '100%',
	'& label': {
		marginRight: theme.spacing(1),
		fontWeight: theme.typography.h3.fontWeight,
		fontSize: theme.typography.body2.fontSize,
		lineHeight: theme.typography.body2.lineHeight,
	}
}))

export const DatepickerStyled = styled(DatePicker)(() => ({
	width: '100%',
	'.MuiPickersDay-root': {
		outline: 'none!important',
	}
}))
