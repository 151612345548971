import {styled} from "@mui/material/styles";

export const BlockWrapper = styled('div', {shouldForwardProp: (propName) => propName !== 'active'})<{
	active?: boolean
}>(({theme, active}) => ({
	borderRadius: theme.shape.borderRadius,
	backgroundColor: active ? theme.palette.green.main : "#FFFFFF",
	padding: '11px 10px',
	display: 'inline-flex',
	color: active ? '#FFFFFF' : theme.palette.text.primary,
	alignItems: 'center',
	minWidth: '200px',
	minHeight: 160,
	paddingTop: '40px',
	width: 'auto',
	textAlign:'center',
	justifyContent: 'flex-start',
	cursor: "pointer",
	flexDirection: 'column',
	transition: 'box-shadow 0.2s linear',
	userSelect: 'none',
	position: 'relative',
	boxShadow: active ? '0px 5px 7px 0px #5243C23B' : 'none',
	"&:hover": {
		boxShadow: '0px 5px 7px 0px #5243C23B',
	}
}))
