/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useContext, useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

import { Box, Container, Grid, Typography } from "@mui/material";
import { ReactComponent as Logo } from 'assets/images/rein_logo.svg';

import Steps from "widgets/Steps/Steps";
import Footer from "widgets/Footer/Footer";
import { ApplicationFormContext } from 'app/providers/applicationForm/lib/ApplicationFormContext';
import { FormDataContext } from 'app/providers/formValues/lib/FormDataContext';
import {
	useCustomerJourneyMutationQuery,
	useJourneyQuery,
	useJourneyResultFormQuery
} from 'entities/ApplicationForm/hooks/queries';
import { useFormBackMutationQuery } from 'entities/ApplicationFormBack/hooks/queries';
import { useProcessMutationQuery } from 'entities/ApplicationProcess/hooks/queries';

import { NavigationBundle, NavigationContext } from 'app/providers/router/ui/NavigationContext';
import { useQuoteQuery } from 'entities/Quote/hooks/queries';
import { useResolutionMutationQuery } from 'entities/Resolution/hooks/queries';
import { useUwQuestionsMutationQuery, useUwQuestionsQuery } from 'entities/UwQuestions/hooks/queries';
import { useAuthMutationQuery } from 'entities/Auth/hooks/queries';
import Loader from '../../shared/ui/Loader/Loader';
import { UwQuestionsProvider } from 'app/providers/UwQuestionsProvider';
import { checkParamType } from '../../shared/utils/checkParamType';

const HomePage = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { pathname, search } = location;
	const {
		setPolicyStartDate,
		setPolicyState,
		setSchemaAddress,
		formId,
	} = useContext(FormDataContext);
	const { setApplicationFormData } = useContext(ApplicationFormContext);
	const { mutateAsync: fetchForm, isSuccess, isError, isPending } = useCustomerJourneyMutationQuery();
	const { mutate: backButton, isSuccess: backSuccess } = useFormBackMutationQuery();
	const { mutate: getApplicationProcess } = useProcessMutationQuery();
	const params = new URLSearchParams(search);
	const client_id: string = params.get('client_id') || localStorage.getItem('client_id') || "";
	const type: string = params.get('type') || checkParamType(client_id) || "";
	const mainQuery = useJourneyQuery()

	const journeyResultData = useJourneyResultFormQuery();
	const [back, setBack] = useState(false);
	const { from, to } = useContext<NavigationBundle>(NavigationContext);

	useEffect(() => {
		if ((!client_id || isError)) {
			navigate("/error");
		}
	}, [isError])

	useEffect(() => {
		if (journeyResultData) {
			const policyStartDate = journeyResultData.quote_result.transactionDetails.policyEffectiveDate;
			const policyAddress = journeyResultData.property_address;

			if (policyStartDate) setPolicyStartDate(policyStartDate);
			if (policyAddress.state) {
				setPolicyState(policyAddress.state);
				setSchemaAddress({
					State: policyAddress.state,
					Address1: policyAddress.street1,
					Address2: policyAddress.street2,
					City: policyAddress.city,
					ZipCode: policyAddress.zip,
					FullAddress: '',
				});
			}
			setApplicationFormData(journeyResultData);
		}
	}, [journeyResultData, setApplicationFormData, setPolicyStartDate, setPolicyState, setSchemaAddress]);

	useEffect(() => {
		const getData = async () => {
			try {
				await fetchForm(client_id);
			} catch (e) {
				console.log('e: ', e);
			}
		}
		if (client_id) {
			getData().then(r => r).catch(e => console.log('e: ', e))
		}
	}, [fetchForm, client_id])

	useEffect(() => {
		const applicationId = localStorage.getItem("client_id");
		if (backSuccess && applicationId && back) {
			getApplicationProcess(applicationId);
		}
	}, [backSuccess]);

	useEffect(() => {
		if (isSuccess && client_id) {
			localStorage.setItem("client_id", client_id);
			if(type) {
				localStorage.setItem("type", JSON.stringify({ type, client_id }));
			}
			if(!type && !checkParamType(client_id)) {
				localStorage.removeItem("type");
			}
		}
	}, [isSuccess, client_id]);
	//
	// useEffect(() => {
	// 	if(moduleId || moduleId === null) {
	// 		localStorage.setItem("moduleId", moduleId as string);
	// 	}
	// }, [moduleId]);

	const goBack = () => {
		navigate(-1);
		setBack(true);
	}

	useEffect(() => {
		if (back) {
			if (formId) backButton(formId);
		}
	}, [back]);

	useEffect(() => {
		if (from?.pathname === "/checkout" && from.key !== "default" && to?.pathname === "/") {
			setBack(true);
		}
	}, [from, to]);

	useEffect(() => {
		if (mainQuery?.journeyStatus === 'issued') {
			window.location.href = 'https://renterscoverage.rein.ai/';
		}
	}, [mainQuery]);

	if (isPending) {
		return <Loader/>
	}
	if (isError) {
		return <Navigate to="/error"/>;
	}
	return (
		<Box>
			{journeyResultData && !isPending && (
				<>
					<Grid
						container
						spacing={0}
						sx={{ minHeight: '100vh' }}
					>
						<Grid item xs={12}>
							<Box sx={{ textAlign: 'center', position: 'relative' }} mt={5}>
								{pathname !== '/' && <Typography
									variant="body1"
									color={"text.secondary"}
									fontSize={14}
									sx={{ mr: 1, cursor: 'pointer', position: 'absolute' }}
									onClick={goBack}
								>
									Back
								</Typography>}
								<Logo width={171} height={40}/>
								<Typography variant="body1" fontSize="10px" lineHeight="12px">Renters Insurance powered
									by Assurant®</Typography>
							</Box>
							<Container maxWidth={"lg"} sx={{ mt: 5 }}>
								<UwQuestionsProvider data={journeyResultData.quote_result} customerData={journeyResultData}>
									<Steps/>
									<Outlet/>
								</UwQuestionsProvider>
								<Footer/>
							</Container>
						</Grid>
					</Grid>
				</>
			)}
		</Box>
	)
}

export default HomePage
