import { createContext, Dispatch, SetStateAction } from 'react';
import { ResolutionResponse, UwQuestionResponse } from 'shared/api/Questions';
import { IFormInput } from '../../formValues/ui/FormDataProvider';

type CustomerStateContextProps = {
	dataQuestions: UwQuestionResponse[] | null;
	dataAnswers: ResolutionResponse | null;
	questionsCollection: Record<string, string>
	setQuestionsData: (data: Record<string, string>) => void;
	getQuestionsDataAsync:() => Promise<void>;
	isLoadingQuestions: boolean;
	setQuestionsCollection: Dispatch<SetStateAction<Record<string, string>>>;
}

export const UwQuestionsContext = createContext<CustomerStateContextProps | null>(null)
