import { Grid, RadioGroup, Typography } from '@mui/material';
import RadioButton from 'shared/ui/RadioButton/RadioButton';
import { StyledButton } from 'shared/ui/Button/styles';
import ModalWindow from 'shared/ui/ModalWindow/ModalWindow';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
interface Props {
	questionModal: boolean;
	onClose: () => void;
	questionAnswer: string;
	handleChange: Dispatch<SetStateAction<string>>;
	onSubmit:  () => void
	isPending:  boolean
	isNeedToCreateOffer:  boolean
	property?:  string
}
const ModalRIQuestion = (props: Props) => {
	const {onClose, questionModal, handleChange, questionAnswer, onSubmit, isPending, isNeedToCreateOffer, property} = props
	const isWood = questionAnswer ? questionAnswer === 'N' : property === 'Wood'
	const isMasonry = questionAnswer ? questionAnswer === 'Y' : property === 'Masonry'
	useEffect(() => {
		if(property) {
			handleChange(property === 'Wood' ? 'N' : 'Y')
		}
	}, [property])
	useEffect(() => {
		return () => {
			handleChange('')
		}
	}, []);
	const handleChangeNC = (event: React.ChangeEvent<HTMLInputElement>) => {
		handleChange((event.target as HTMLInputElement).value);
	};
	return (
		<ModalWindow open={questionModal} handleClose={onClose} header={
			<Typography variant="h4" mb={0}>Just covering the bases</Typography>
		}
		>
			<Grid item md={12} sm={12} xs={12}>
				<Typography variant="subtitle2" fontWeight="normal" >
					What is your rental property made of?
				</Typography>
			</Grid>
			<Grid item md={12} sm={12} xs={12}>
				<RadioGroup
					value={questionAnswer !== '' ? questionAnswer : property === 'Wood' ? 'N' : 'Y'}
					onChange={handleChangeNC}
					aria-label="icon-radio"
				>
					<Grid container spacing={2}>
						<Grid item md={6} sm={6} xs={12}>
							<RadioButton
								checked={isWood}
								value={'N'}
								label="Wood Frame"
								label2={""}
							/>
						</Grid>
						<Grid item md={6} sm={6} xs={12}>
							<RadioButton
								checked={isMasonry}
								value={'Y'}
								label="Masonry"
								label2={""}
							/>
						</Grid>
					</Grid>
				</RadioGroup>
			</Grid>
			<Grid item md={12} sm={12} xs={12} justifyContent="center">
				{isPending && isNeedToCreateOffer && (
					<Typography variant="h4" textAlign="center" mb={0}>
						Please stand by while we create a new quote for you.
					</Typography>
				)}
			</Grid>
			<Grid item md={4} xs={8} sm={6}>
				<StyledButton variant="outlined" onClick={onSubmit} loading={isPending}>
					CONTINUE
				</StyledButton>
			</Grid>
		</ModalWindow>
	)
}

export default ModalRIQuestion
