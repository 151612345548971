import { paramsAdapt } from 'shared/utils/paramsAdapt';
import { JourneyResult } from '../config/types/customerResponse';
import { QuotePayload } from '../api/assurant/quotePayloadTypes';

export const payloadDataTransform = (customerData: JourneyResult, questions: Record<string, string>) => {
	const underwritingData = customerData.underwriting.questions.reduce<Record<string, string>>((accum, item) => {
		accum[item.questionName] = item.answer
		return accum
	}, {})
	const params = paramsAdapt(customerData)
	let questionsData: Record<string, string> = {...underwritingData}
	questionsData = {...questionsData, ...questions}
	const questionsResults = Object.keys(questionsData).map((key) => ({questionName: key, answer: questionsData[key]}))
	const schemaAddress = {
		address1: customerData.property_address.street1,
		address2:  customerData.property_address.street2,
		city:  customerData.property_address.city,
		state:  customerData.property_address.state,
		postalCode:  customerData.property_address.zip,
	}
	const newPayload: QuotePayload = {
		coverage: {
			parameters: params,
		},
		transaction: {
			productType: customerData.quote_result.transactionDetails.productId,
			clientId: customerData.quote_result.transactionDetails.clientId,
			workItemId: customerData.quote_result.transactionDetails.workItemId,
			jobNumber: "000932",
			referralId: customerData.quote_result.transactionDetails.referralId,
			policyEffectiveDate: customerData.coverage_info.effective_date
		},
		policyHolder: {
			insured: {
				address: schemaAddress
			},
		},
		underWriting: {
			questions: [
				...questionsResults
			]
		}
	}
	return newPayload
}
