import React, { useContext, useEffect, useState } from 'react'
import { useFormContext } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";

import { Grid, useMediaQuery } from "@mui/material";

import DetailsList from "../DetailsList/DetailsList";
import BelongingsSlider from "../BelongingsSlider/BelongingsSlider";
import Liability from "../Liability/Liability";
import Deductible from "../Deductible/Deductible";

import { StyledButton } from "shared/ui/Button/styles";
import { FormDataContext } from "app/providers/formValues/lib/FormDataContext";
import { ApplicationFormContext } from 'app/providers/applicationForm/lib/ApplicationFormContext';
import { IFormInput } from 'app/providers/formValues/ui/FormDataProvider';
import AdditionalProtection from "widgets/AdditionalProtection/AdditionalProtection";
import { Sidebar } from 'features/CoverageSidebar';
import {
	useCustomerDataUpdateMutationQuery,
	useCustomerJourneyMutationQuery,
	useJourneyQuery,
	useJourneyResultFormQuery
} from 'entities/ApplicationForm/hooks/queries';
import { JourneyResult } from 'shared/config/types/customerResponse';
import { changeFieldsValue } from 'shared/utils/changeFieldsValue';
import { useCreateTenant, useGetTenant, useMutationQuery } from 'entities/Quote/hooks/queries';
import ModalRIQuestion from './ModalRIQuestion';
import ModalNCQuestion from './ModalNCQuestion';
import { Question } from 'shared/api/assurant/quotePayloadTypes';
import { checkParamType } from '../../shared/utils/checkParamType';

const RentersForm = () => {
	const { setFormData, protections, schemaAddress, initialPayload } = useContext(FormDataContext);
	const { setApplicationFormData } = useContext(ApplicationFormContext);
	const { mutate: updateData, isSuccess: fetchSuccess } = useCustomerDataUpdateMutationQuery();
	const { mutateAsync: createNext, isSuccess: quoteSuccess, data, isError } = useMutationQuery();
	const { mutate: fetchForm } = useCustomerJourneyMutationQuery();
	const journeyData = useJourneyQuery()
	const { mutateAsync: getTenant } = useGetTenant()
	const { mutateAsync: createOffer } = useCreateTenant()
	const [questionModal, setQuestionModal] = useState<string | null>(null);
	const [riQuestionAnswer, setRiQuestionAnswer] = useState('');
	const [ncQuestionAnswer, setNCQuestionAnswer] = useState('');
	const matchesSM = useMediaQuery('(max-width:900px)');
	const [isPending, setIsPending] = useState(false);
	const query = useJourneyResultFormQuery();
	const [isNeedToCreateOffer, setIsNeedToCreateOffer] = useState(false);

	const urlSearchString = window.location.search;
	const params = new URLSearchParams(urlSearchString);
	const client_id: string = params.get('client_id') || localStorage.getItem('client_id') || "";
	const type: string = params.get('type') || checkParamType(client_id) || "";

	const navigate = useNavigate();

	const isSmall = useMediaQuery('(max-width:600px)');
	const { handleSubmit } = useFormContext<IFormInput>();

	const needsToCreateNewOffer = (answer: string, propertyMaterial?: string, coverageMasonry?: string, isMasonryConstruction?: string, ) => {
		if(propertyMaterial === 'Wood' && coverageMasonry === 'N' && isMasonryConstruction === 'N' && answer === 'N') {
			return false
		}
		return !(propertyMaterial === 'Masonry' && coverageMasonry === 'Y' && isMasonryConstruction === 'Y' && answer === 'Y');

	}
	const onSubmit = () => {
		if (schemaAddress.State === 'RI' && !riQuestionAnswer) {
			setQuestionModal('RI')
			return
		}
		if (schemaAddress.State === 'NC' && !ncQuestionAnswer) {
			setQuestionModal('NC')
			return
		}
		setIsPending(true);
		void handleSubmit(async (data) => {
			setFormData(data);
			if (query) {
				let underWritingQuestionsData = query.underwriting.questions
				const newCoverageInfoParams = changeFieldsValue(query.coverage_info.parameters, protections, data)
				const quoterResultParams = changeFieldsValue(query.quote_result.coverageDetails.parameters, protections, data)
				let newQuestions: Question[] = []
				if(riQuestionAnswer) {
					newQuestions = [{
						questionName: 'Arson', answer: riQuestionAnswer
					}]
					underWritingQuestionsData = initialPayload.underWriting.questions.filter((item) => item.questionName !== 'Arson')
				}
				const underwritingQuestions = newQuestions.length > 0 ? [
					...underWritingQuestionsData, ...newQuestions
				] : initialPayload.underWriting.questions
				const queryData: JourneyResult = {
					...query,
					coverage_info: {
						...query.coverage_info,
						parameters: newCoverageInfoParams
					},
					quote_result: {
						...query.quote_result,
						coverageDetails: {
							parameters: quoterResultParams
						},
					},
					underwriting: {
						questions: underwritingQuestions
					}
				}
				if(schemaAddress.State === 'RI' || schemaAddress.State === 'NC') {
					const newPayload = {
						...initialPayload,
						underWriting : {
							questions: underwritingQuestions
						},
					}
					if(schemaAddress.State === 'RI') {
						const data = await createNext(newPayload)
					}
					if(schemaAddress.State === 'NC'){
						const coverageMasonry = initialPayload.underWriting.questions
							.find((item) => item.questionName === 'coverageMasonry')
						const isMasonryConstruction = initialPayload.underWriting.questions
							.find((item) => item.questionName === 'isMasonryConstruction')
						const isNeedToCreateNewOffer = needsToCreateNewOffer(
							ncQuestionAnswer,
							journeyData?.journeyResult.property_info.property_material,
							coverageMasonry?.answer,
							isMasonryConstruction?.answer,
						)
						setIsNeedToCreateOffer(isNeedToCreateNewOffer)
						if(isNeedToCreateNewOffer) {
							const tenant = await getTenant(localStorage.getItem('client_id')!)
							const newPayload = {
								...tenant.data.movedRequest,
								propertyInfo: {
									...tenant.data.movedRequest.propertyInfo,
									propertyMaterial: ncQuestionAnswer === 'Y' ? 'Masonry' : 'Wood'
								}
							}
							const offer = await createOffer(newPayload)
							if(offer.data){
								const newUrl = location.protocol + '//' + location.host + location.pathname
								const typeString = type=='anonymous' ? `&type=anonymous` : ''
								window.location.href = `${newUrl}?client_id=${offer.data.referenceId as unknown as string}${typeString}`
							}
						} else {
							const data = await createNext(newPayload)
						}
					}
				}
				if (journeyData) {
					updateData({
						customerJourneyId: journeyData.customerJourneyId,
						stageName: "QuoteCreated",
						stageId: "",
						model: JSON.stringify(queryData),
					})
				}
			}
		})()
	}

	useEffect(() => {
		const quoteSuccessRequest = schemaAddress.State === 'RI' || schemaAddress.State === 'NC' ? quoteSuccess : true
		const error = data?.data.transactionDetails.responseMessage
		if (fetchSuccess && quoteSuccessRequest && !error) {
			fetchForm(client_id)
			if (query) {
				setApplicationFormData(query);
			}
			onClose()
			setIsPending(false)
			if(type === 'anonymous') {
				navigate('/questions');
			} else {
				navigate('/checkout');
			}
		}
	}, [fetchSuccess, quoteSuccess]);

	const onClose = () => {
		setRiQuestionAnswer('')
		setNCQuestionAnswer('')
		setQuestionModal(null)
	}

	const handleChangeRI = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRiQuestionAnswer((event.target as HTMLInputElement).value);
	};

	if (isError && !isPending) {
		return <Navigate to="/error"/>;
	}
	return (
		<div>
			<form>
				<DetailsList/>
				<Liability/>
				<BelongingsSlider/>
				<AdditionalProtection/>
				<Deductible/>
				{matchesSM && <Sidebar/>}
				<Grid container justifyContent={isSmall ? "center" : "end"}>
					<Grid item md={4} xs={8} sm={6}>
						<StyledButton variant="outlined" onClick={onSubmit} loading={isPending}>
							CONTINUE
						</StyledButton>
					</Grid>
				</Grid>
				{questionModal === 'RI' && (
					<ModalRIQuestion
						questionModal={questionModal === 'RI'}
						onClose={onClose}
						questionAnswer={riQuestionAnswer}
						handleChange={handleChangeRI}
						onSubmit={onSubmit}
						isPending={isPending}
					/>)}
				{questionModal === 'NC' && (
					<ModalNCQuestion
						questionModal={questionModal === 'NC'}
						onClose={onClose}
						questionAnswer={ncQuestionAnswer}
						handleChange={setNCQuestionAnswer}
						onSubmit={onSubmit}
						isPending={isPending}
						isNeedToCreateOffer={isNeedToCreateOffer}
						property={journeyData?.journeyResult.property_info.property_material}
					/>)}
			</form>
		</div>
	)
}

export default RentersForm
