import React from 'react'
import { LinkStep, StepItem, StepWrapper } from "./styles";
import { Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import { AppRoutes, RoutePath } from 'shared/config/routeConfig/routeConfig';
import { checkParamType } from 'shared/utils/checkParamType';

const Steps = () => {
	const location = useLocation();
	const { pathname, search } = location;
	const params = new URLSearchParams(search);
	const client_id: string = params.get('client_id') || localStorage.getItem('client_id') || "";
	const type: string = params.get('type') || checkParamType(client_id) || "";

	const splitLocation = pathname.split("/");
	return (
		<StepWrapper>
			<Grid container spacing={2} alignContent="center" justifyContent="center">
				<Grid item md={6}>
					<Grid container spacing={2} alignContent="center" justifyContent="center">
						<Grid item md={3} sm={3} xs={3}>
							<LinkStep to={RoutePath[AppRoutes.MAIN]}>
								<StepItem active={splitLocation[1] === ""}>Quote</StepItem>
							</LinkStep>
						</Grid>
						{type === 'anonymous' && <Grid item md={3} sm={3} xs={3}>
							<LinkStep to={RoutePath[AppRoutes.QUESTIONS]} disabled={splitLocation[1] === ""}>
								<StepItem active={splitLocation[1] === "questions"}>Questions</StepItem>
							</LinkStep>
						</Grid>}
						<Grid item md={3} sm={3} xs={3}>
							<LinkStep to={RoutePath[AppRoutes.CHECKOUT]} disabled={splitLocation[1] === "" || splitLocation[1] === "questions"}>
								<StepItem active={splitLocation[1] === "checkout"}>Checkout</StepItem>
							</LinkStep>
						</Grid>
						<Grid item md={3} sm={3} xs={3}>
							<LinkStep to={RoutePath[AppRoutes.CHECKOUT]} disabled={splitLocation[1] === "" || splitLocation[1] === "checkout" }>
								<StepItem active={splitLocation[1] === "summary"}>Review</StepItem>
							</LinkStep>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</StepWrapper>
	)
}

export default Steps
