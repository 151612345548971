import { ParamType } from '../config/types/types';

export const checkParamType = (clientId: string) => {
	const item = localStorage.getItem('type');
	try {
		if(item) {
			const data: ParamType = JSON.parse(item) as ParamType;
			if(data.client_id === clientId) {
				return data.type;
			}
			return null
		}
	} catch (error) {
		console.log('error: ', error);
		return null
	}

}
