/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { $uwquestionsApi } from '../../index';
import { AxiosResponse } from 'axios';
import {  ResolutionResponse } from './types';
import { UwQuestionResponse } from '../../Questions';

export default class UwQuestions {
	static async getQuestions(params: string): Promise<AxiosResponse<UwQuestionResponse[]>> {
		return $uwquestionsApi.get(params)
	}
	static async sendAnswers(props: any): Promise<AxiosResponse<ResolutionResponse>> {
		return $uwquestionsApi.post(props.outcomeUrl, props.data);
	}
}
