import { useContext } from 'react';
import { UwQuestionsContext } from '../lib/UwQuestionsContext';

export const useQuestionsData = () => {
	const context = useContext(UwQuestionsContext)
	if(!context) {
		throw new Error("useQuestionsData must be used within the UwQuestionsContext")
	}
	return context
}
