import { ReactComponent as InfoIcon } from 'assets/icons/info_icon.svg';
import { ImgBlock, InfoBlock } from './styles';
import { Typography } from '@mui/material';

export const Info = () => {
	return (
		<InfoBlock>
			<ImgBlock>
				<InfoIcon />
			</ImgBlock>
			<Typography variant="body1" color="secondary.main">
				A loss is any time there’s been a theft, injury or damage to property, whether it was covered or not.
			</Typography>
		</InfoBlock>
	)
}
