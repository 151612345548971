/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React from 'react'
import { Grid, RadioGroup, Typography } from "@mui/material";
import { useJourneyResultFormQuery } from 'entities/ApplicationForm/hooks/queries';
import { Controller, useForm } from 'react-hook-form';
import { Info } from 'shared/ui/Info';
import { useQuestionsData } from 'app/providers/UwQuestionsProvider';
import RadioButton from 'shared/ui/RadioButton/RadioButton';
import { StyledButton } from 'shared/ui/Button/styles';
import { payloadDataTransform } from 'shared/utils/payloadDataTransform';
import { useMutationQuery } from 'entities/Quote/hooks/queries';
import { useNavigate } from 'react-router-dom';

export interface QuestionsPageProps {
	NCManufactured: string
	requiredInsurance: string
	naturalDisaster: string
	lastLossDate?: string
	rhodeIslandLoses: string
	animalInsured: string
}

const naturalDisasterOptions: { value: string, label: string }[] = [
	{ value: 'N', label: 'None' },
	{ value: 'Y', label: 'One' },
	{ value: 'twoOrMore', label: 'Two or More' },
]
const requiredInsuranceOptions: { value: string, label: string }[] = [
	{ value: 'Y', label: 'Yes' },
	{ value: 'N', label: 'No' },
]
const animalOptions: { value: string, label: string }[] = [
	{ value: 'Y', label: 'Yes' },
	{ value: 'N', label: 'No' },
]
const NCManufacturedOptions: { value: string, label: string }[] = [
	{ value: 'N', label: 'Wood Frame' },
	{ value: 'Y', label: 'Masonry' },
]
const QuestionsPage = () => {
	const navigate = useNavigate();
	const { mutateAsync: createNext, isPending } = useMutationQuery();
	const journeyResultData = useJourneyResultFormQuery();
	const {
		getQuestionsDataAsync,
		questionsCollection,
		isLoadingQuestions,
		setQuestionsCollection
	} = useQuestionsData()
	const {
		handleSubmit,
		control,
		watch
	} = useForm<QuestionsPageProps>({
		defaultValues: {
			NCManufactured: questionsCollection?.NCManufactured,
			requiredInsurance: questionsCollection['IsLiabilityRequiredByLandLordOrPMC'] ?? '',
			naturalDisaster: questionsCollection['LiabilityLosesInPastThreeYears'] ?? '',
			animalInsured: questionsCollection['hadAnimalInjury'] ?? '',
		}, mode: "onSubmit"
	});
	const fieldValue = watch('naturalDisaster')
	// const ifNCState = journeyResultData && journeyResultData.property_address.state === 'NC'
	// const ifRIState = journeyResultData && journeyResultData.property_address.state === 'RI'

	const saveData = () => {
		void handleSubmit(async (data) => {
			if (data.animalInsured === 'Y') {
				window.location.href = 'https://renterscoverage.rein.ai/';
				return;
			}
			const quCollections: Record<string, string> = {
				"hadAnimalInjury": data.animalInsured,
				"AnimalsCausedBodlyInjuredPastThreeYears": data.animalInsured,
				"LiabilityLosesInPastThreeYears": data.naturalDisaster,
				...(data.NCManufactured && {"isMasonryConstruction": data.NCManufactured}),
				...(data.rhodeIslandLoses && {"Arson": data.rhodeIslandLoses}),
				"IsLiabilityRequiredByLandLordOrPMC": data.requiredInsurance
			}
			if(journeyResultData) {
				const newPayload = payloadDataTransform(journeyResultData, quCollections)
				setQuestionsCollection(quCollections);
				const data = await createNext(newPayload)
				await getQuestionsDataAsync()
				if(data.data) {
					navigate('/checkout')
				}
			}

		})()
	};
	if (!journeyResultData) return null
	const isLoading = isLoadingQuestions || isPending
	return (
		<form>
			<Grid container spacing={6}>
				<Grid item md={6} lg={6}>
					<Typography variant="subtitle2" mb={2}>Are you required to have renters insurance?</Typography>
					<Controller
						name="requiredInsurance"
						control={control}
						rules={{ required: true }}
						render={({ field }) => {
							return (
								<RadioGroup
									{...field}
									aria-label="icon-radio"
								>
									<Grid container spacing={3}>
										{requiredInsuranceOptions &&
											requiredInsuranceOptions.map((item) => {
												return (
													<Grid item md={6} sm={6} xs={12} key={item.label}>
														<RadioButton
															checked={field.value === item.value}
															value={item.value} label={item.label}
														/>
													</Grid>
												)
											})
										}
									</Grid>
								</RadioGroup>
							)
						}}/>
				</Grid>
				<Grid item md={6} lg={6}>
					<Typography variant="subtitle2" mb={2}>Do You Own or Care for An Animal That Has Harmed
						Someone?</Typography>
					<Controller
						name="animalInsured"
						control={control}
						rules={{ required: true }}
						render={({ field }) => {
							return (
								<RadioGroup
									{...field}
									aria-label="icon-radio"
								>
									<Grid container spacing={3}>
										{animalOptions &&
											animalOptions.map((item) => {
												return (
													<Grid item md={6} sm={6} xs={12} key={item.label}>
														<RadioButton
															checked={field.value === item.value}
															value={item.value} label={item.label}
														/>
													</Grid>
												)
											})
										}
									</Grid>
								</RadioGroup>
							)
						}}/>
				</Grid>
				<Grid item md={6} lg={6} mb={2}>
					<Typography variant="subtitle2" mb={2}>Excluding Natural Disasters, How Many Losses Have You Had in
						the
						Past 3
						Years?</Typography>
					<Controller
						name="naturalDisaster"
						control={control}
						rules={{ required: true }}
						render={({ field }) => {
							return (
								<RadioGroup
									{...field}
									aria-label="icon-radio"
								>
									<Grid container spacing={3}>
										{naturalDisasterOptions &&
											naturalDisasterOptions.map((item) => {
												return (
													<Grid item md={4} sm={4} xs={12} key={item.label}>
														<RadioButton
															checked={field.value === item.value}
															value={item.value} label={item.label}
														/>
													</Grid>
												)
											})
										}
									</Grid>
								</RadioGroup>
							)
						}}/>
					<Info/>
				</Grid>
			</Grid>
			<Grid container justifyContent={"center"}>
				<Grid item md={4} xs={8} sm={6}>
					<StyledButton variant="outlined" onClick={saveData} loading={isLoading}>
						CONTINUE
					</StyledButton>
				</Grid>
			</Grid>
		</form>
	)
}

export default QuestionsPage
