import { FC, ReactElement, ReactNode } from 'react';
import { reactPlugin, withAppInsights } from '../config/AppInsightsInstance';
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";

interface Props {
	children: ReactNode
}

export const AppInsightsContextProvider: FC<Props> = ({ children }) => {
	return (
		<AppInsightsContext.Provider value={reactPlugin}>
			{children}
		</AppInsightsContext.Provider>
	);
};

interface Props {
	children: ReactNode
}
export const WithAppInsight = withAppInsights(({ children }: Props): ReactElement<any, any> | null => {
	return children as ReactElement<any, any>;
});
interface AppInsightsContextProps {
	children: ReactNode
}
export const WithEventData =({ children }: AppInsightsContextProps) => {
	return <WithAppInsight>{children}</WithAppInsight>
};
