export enum AppRoutes {
	MAIN = 'main',
	CHECKOUT = 'checkout',
	CONFIRMATION = 'confirmation',
	QUESTIONS = 'questions',
	ERROR = 'error',
	NOT_FOUND = 'notFound',
	SUMMARY = 'summary'
}

export const RoutePath: Record<AppRoutes, string> = {
	[AppRoutes.MAIN]: '/',
	[AppRoutes.CONFIRMATION]: '/confirmation',
	[AppRoutes.ERROR]: '/error',
	[AppRoutes.CHECKOUT]: '/checkout',
	[AppRoutes.QUESTIONS]: '/questions',
	[AppRoutes.SUMMARY]: '/summary',
	[AppRoutes.NOT_FOUND]: '*'
}
